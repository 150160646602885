import React from "react";
import march2020 from "../static/newsletters/Newsletter_March2020.pdf";

const NewsLetters = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Newsletters
          </li>
        </ol>
      </nav>

      <ul>
        <li>
          <a href={march2020}>Newsletter - March 2020</a>
        </li>
      </ul>
    </div>
  );
};

export default NewsLetters;
