import React from "react";

import calendarJpg from "../static/calendars/HSHB_Calendar_2024-25.jpg";

const Calendars = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Calendars
          </li>
        </ol>
      </nav>

      <div>
        <img
          src={calendarJpg}
          style={{ width: "90%", height: "90%" }}
          alt="calendar 2022-2023"
        />
      </div>
    </div>
  );
};

export default Calendars;
