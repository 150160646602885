import React from "react";

const ContactDetails = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Contact Details
          </li>
        </ol>
      </nav>

      <address>
        <strong>Hellenic School Of High Barnet</strong>
        <br />
        Chestnut Grove
        <br />
        Barnet, EN4 8PU
        <br />
      </address>

      <hr className="my-4" />

      <p>
        The school is served by good public transport links with the 307, 298,
        184 and 384 bus routes all making stops close to our school.
      </p>
      <p>
        Our school is near to Cockfosters and Oakwood tube station as well as
        Oakleigh Park Rail Station.
      </p>
      <p>
        Cycle storage racks are available enabling students to ride their bikes
        to school and store them securely.
      </p>

      <hr className="my-4" />

      <div className="card-deck mb-3">
        <div className="card">
          <div className="card-header">
            Lower School Head(Nursery to Year 5)
          </div>
          <div className="card-body">
            <h4 className="card-title">Vasilis Kalaitzidis</h4>
            <p className="card-text">e-mail: head@hshb.org.uk </p>
            <p className="card-text">
              Telephone:
              <ul>
                <li>07747 147 828</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            Upper School Head(Year 6 to A Level)
          </div>
          <div className="card-body">
            <h4 className="card-title">Jenny Petridou</h4>
            <p className="card-text">e-mail: info@hshb.org.uk</p>
          </div>
        </div>

        <div className="card">
          <div className="card-header">Chairman</div>
          <div className="card-body">
            <h4 className="card-title">George Keliris</h4>
            <p className="card-text">e-mail: info@hshb.org.uk</p>
            <p className="card-text">
              Telephone:
              <ul>
                <li>07753 829 692</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
