import React, { Component } from "react";
import schoolJpg from "../static/assets/photos/school.jpg";
import flagGreeceCyprus from "../static/assets/flag_greece_cyprus.svg";

import riskAssessment from "../static/riskassessment/HSHB - Registration Form 2024-25.pdf";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <div
              className="row"
              style={{
                borderStyle: "solid",
                borderWidth: "7px",
                borderColor: "red",

                margin: "5px",
              }}
            >
              <p
                className="lead"
                style={{ margin: "5px", fontWeight: "bolder" }}
              >
                Dear Parents,
                <p>
                  The last day of school is 13th of July. We look forward to
                  welcoming you all back for the 1st day of the new academic
                  year on the 14th of September. If you are looking at
                  registering a new child, please send us an email at
                  info@hshb.org.uk to receive the registration documents you
                  will need to complete.
                </p>
                <p>Kind Regards</p>
                <p>Hellenic School of High Barnet Committee</p>
              </p>
            </div>

            <div className="row">
              <div className="jumbotron">
                <h1 className="display-4">
                  The Hellenic School of High Barnet Committee
                </h1>

                <p className="lead">
                  Our school is a non-political Independent establishment
                  providing education in the Greek language. We meet in person
                  on Saturdays in the newly built East Barnet School, and have
                  additional online lessons on Wednesday evenings for children
                  in Year 3 and above. The school was founded in 1977 starting
                  with 40 pupils, with the number increasing to just under 200
                  in 2021.
                </p>

                <hr className="my-4" />
                <img
                  src={schoolJpg}
                  className="img-fluid mx-auto d-block"
                  alt="school"
                />
              </div>
            </div>

            <div className="row">
              <p>
                We don’t just teach our children the Greek language, we also
                educate and enhance their knowledge of Greek and Cypriot
                lifestyles; from the Greek Orthodox religion, to the history and
                culture of the Hellenes. This includes traditional costumes,
                dance and music, celebrations of popular events, folk plays,
                what it was like to be Greek in our grandparents' time, and to
                move with changing times.
              </p>

              <p>
                We aim to pass on enduring values of our Greek heritage and at
                the same time display respect and appreciation of different
                beliefs and cultures. We also aim to achieve high standards in
                education and preparation for GCSE and A Level examinations. We
                are proud of our successes in examinations, the results are
                high, and continually improving.
              </p>

              <p>
                To visit our school or discuss any educational matters in
                person, please pop along during the school term on any Saturday
                morning between 10.00-12.00, and meet our Headmaster who will
                happily answer any questions you may have, and show you around
                the school facilities.
              </p>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="card mb-3">
              <img className="card-img-top" src={flagGreeceCyprus} alt="" />
              <div className="card-body">
                <h4 className="card-title">
                  A fabulous, fun atmosphere for children to learn about the
                  Greek language, Greek & Cypriot culture, dance and customs.
                </h4>
                <p className="card-text">
                  <ul>
                    <li>Greek language</li>
                    <li>Greek &amp; Cypriot culture</li>
                    <li>Greek dance</li>
                    <li>Fun, safe environment</li>
                    <li>From pre-school to GCSE Level</li>
                    <li>Edexcel curriculum</li>
                    <li>GCSE</li>
                    <li>Outstanding results</li>
                    <li>Brand new school building</li>
                    <li>DBS checked staff</li>
                    <li>Strong community ties</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">Term dates</div>
              <div className="card-body">
                <h4 className="card-title">2024-2025</h4>
                <p className="card-text">
                  Starts Saturday 14<sup>th</sup> September 2024
                </p>
                {/*
                <a  className="btn btn-primary" >
                  Click here.
                </a>
                */}
                <p>
                  We are accepting Registrations for the 2024/25 academic year.
                  If you wish to enroll please contact info@hshb.org.uk
                </p>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-header">Registration Form</div>
              <div className="card-body">
                <h4 className="card-title">
                  <a href={riskAssessment}>Registration Form - Pdf</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
