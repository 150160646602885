import React from "react";

const ExamResults = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Exam Results
          </li>
        </ol>
      </nav>

      <h2>Results for the academic years 2019 - 2022.</h2>

      <p>
        The 2021-2022 Academic Year, was the first in 2 years that was not
        teacher assessed due to Covid. This added with the new split in GCSE
        papers to foundation and higher, meant that overall whilst the results
        were strong, there is room for improvement.
      </p>
      <p>
        With that in mind and because here at The Hellenic School of High Barnet
        we always strive to improve, and in 2021-2022 many discussions were had,
        as to how we can better serve our children by increasing the educational
        standard at our school. To that end the governing board and then
        headmaster Kostas Ladas, agreed that we should look at splitting the
        into an Upper and Lower school, to allow for increased teacher led
        assessment and more focused learning from each of the respective new
        Headmasters.
      </p>
      <p>
        As a result we appointed in the 2022-23 academic year 2 new
        Headsmasters, who started the process for changing the way Greek is
        taught. This process will continue throughout 2023-24. 
      </p>
      <p>
        <b>Jenny Petridou</b> will Head Years 6 to A Level, helping to reform
        and ensure that the children entering GCSE are prepared and well placed
        to take and pass the exam. A long standing teacher of classics whose
        last position in Greece was as the Academic Deputy Head and Director of
        studies. Since 2015 she has been working both in English and Greek
        schools at mainly GCSE and A Level years, and is an Examiner for the
        Modern Greek exam via the Edexcel board. As our A Level teacher for
        nearly a decade, she is intimately familiar with the unique challenges
        the new Upper School will face.
      </p>
      <p>
        <b>Vasilis Kalaitzidis</b> will Head Nursery through to Year 5, allowing
        the early year progression to focus on the fundamentals and ensuring
        that they all meet the new standards that will be set. Qualifying as a
        teacher in Greece where he studied teaching strategies for Primary
        education, and then completed a Master’s in Special education, he is
        currently working at St Andrew the Apostle, co-leading the SEN
        department. Having worked as a teacher at our school for the last 5
        years he has the perspective necessary to make the required improvements
        to our curriculum.
      </p>

      <p>
        With both new Heads having lots of improvements plans and changes, that
        will impact every year group, we look forward to doing something that
        will put us at the forefront of all Greek Schools.
      </p>

      <table className="table table-bordered">
        <thead>
          <th scope="col">
            <strong>GCSE Grades</strong>
          </th>
          <th scope="col">Grade 9</th>
          <th scope="col">Grade 7-8</th>
          <th scope="col">Grade 5-6</th>
          <th scope="col">Grade 4</th>
          <th scope="col">
            <strong>% of total students achieving grade 4 - 9</strong>
          </th>
        </thead>
        <tbody>
          <tr className="table-success">
            <th scope="row">Academic Year 2021-22</th>
            <td>0</td>
            <td>33%</td>
            <td>17%</td>
            <td>25%</td>
            <td>75%</td>
          </tr>
          <tr>
            <th scope="row">Academic Year 2020-21</th>
            <td>75%</td>
            <td>25%</td>
            <td>0</td>
            <td>0</td>
            <td>100%</td>
          </tr>
          <tr>
            <th scope="row">Academic Year 2019-20</th>
            <td>15%</td>
            <td>30%</td>
            <td>35%</td>
            <td>10%</td>
            <td>90%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ExamResults;
