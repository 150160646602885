import React from "react";
import "./App.css";
import Base from "./components/Base";
import Footer from "./components/Footer";
import Home from "./components/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ClassesCirriculum from "./components/ClassesCirriculum";
import OurStaff from "./components/OurStaff";
import ExamResults from "./components/ExamResults";
import Policies from "./components/Policies";
import Aesa from "./components/Aesa";
import TeachingVacancies from "./components/TeachingVacancies";
import NewsLetters from "./components/NewsLetters";
import SocialEvents from "./components/SocialEvents";
import ContactDetails from "./components/ContactDetails";
import Fees from "./components/Fees";
import TermDates from "./components/TermDates";
import Calendars from "./components/Calendars";

function App() {
  return (
    <BrowserRouter>
      <div className="container mb-3">
        <Base />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/classes-curriculum" component={ClassesCirriculum} />
          <Route path="/our-staff" component={OurStaff} />
          <Route path="/exam-results" component={ExamResults} />
          <Route path="/policies" component={Policies} />
          <Route path="/aesa" component={Aesa} />
          <Route path="/teaching-vacancies" component={TeachingVacancies} />
          <Route path="/newsletters" component={NewsLetters} />
          <Route path="/social-events" component={SocialEvents} />
          <Route path="/contact-details" component={ContactDetails} />
          <Route path="/fees" component={Fees} />
          <Route path="/term-dates" component={TermDates} />
          <Route path="/calendars" component={Calendars} />
        </Switch>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
