import React, { Component } from "react";
import hshbLogo from "../static/assets/brand/hshb_logo.png";
import instagramLogo from "../static/assets/brand/Instagram_Glyph_Gradient.png";
import facebookLogo from "../static/assets/brand/f_logo_RGB-Blue_512.png";

class Base extends Component {
  render() {
    return (
      <div style={{ padding: "5px" }}>
        <nav
          className="navbar navbar-expand-lg navbar-light  rounded"
          style={{ backgroundColor: "#e3f2fd", cursor: "pointer" }}
        >
          <a className="navbar-brand" href="/" style={{ cursor: "pointer" }}>
            <img
              src={hshbLogo}
              width="60"
              height="72"
              className="d-inline-block align-top "
              alt=""
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigationbar"
            aria-controls="navigationbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-md-center"
            id="navigationbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown active">
                <div
                  className="nav-link dropdown-toggle"
                  id="maindropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Our School
                </div>
                <div className="dropdown-menu" aria-labelledby="maindropdown">
                  <a className="dropdown-item" href="/classes-curriculum">
                    Classes / Curriculum
                  </a>
                  <a className="dropdown-item" href="/exam-results">
                    Exam Results
                  </a>
                  <a className="dropdown-item" href="/policies">
                    Policies
                  </a>
                  <a className="dropdown-item" href="/aesa">
                    AESA
                  </a>
                  <a className="dropdown-item" href="/teaching-vacancies">
                    Teaching Vacancies
                  </a>
                  {/*<a className="dropdown-item" href="{% url 'recommend' %}">
                    Recommend a Friend
                  </a>*/}
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/our-staff">
                  Our staff
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/calendars">
                  Calendar
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/contact-details">
                  Contact Details
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/fees">
                  Fees
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.instagram.com/eastbarnet_hellenic/?hl=en-gb"
                  target="_blank"
                  alt=""
                >
                  <img src={instagramLogo} width="30" height="30" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://en-gb.facebook.com/eastbarnetgreekschool/"
                  target="_blank"
                  alt=""
                >
                  <img src={facebookLogo} width="30" height="30" />
                </a>
              </li>
            </ul>
            <div className="collapse navbar-collapse" id="mainMenu"></div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Base;
