import React from "react";

const Policies = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Policies
          </li>
        </ol>
      </nav>

      <h2>Policies</h2>
      <p>The rules and regulations have as their main purpose:</p>
      <p>
        To inform parents how the School operates and to bring to their notice
        their responsibilities and liabilities towards the School which their
        children attend and to make the children aware of their duties towards
        the School.
      </p>
      <p>
        Compliance with these rules and regulations will help to create a more
        orderly and conducive environment for study.
      </p>
      <p>
        Observance of the School rules and regulations is obligatory by all
        pupils of all ages.
      </p>

      <h2>Registration</h2>
      <p>
        Registration for pupils intending to attend the School in any one year
        will be completed prior to the end of the first week of October.
      </p>
      <p>
        Pupils who have attained the age of 6 years by the end of September in
        the year of registration will be registered in the first form.
      </p>
      <p>
        Pupils who have attained the age of 5 years by the end of September in
        the year of registration will be registered in the reception class.
      </p>
      <p>
        Pupils who have attained the age of 4 years by the end of September in
        the year of registration will be registered in the nursery.
      </p>
      <p>
        Children who attain the age of 4 years at any other time during the year
        may be registered in the nursery providing there are available places.
        Parents of any other age group who wish to register their children can
        only do so after being interviewed personally by the Headmaster. These
        children will be given both a written and an oral test to assess their
        standard before being admitted and only they will then only be admitted
        should there be a vacancy in the appropriate class.
      </p>

      <h2>School Hours</h2>
      <p>
        Wednesday at 6:10pm - 7:40pm <strong>(Year 3 upwards only)</strong>
      </p>
      <p>Saturdays 9.30am - 1.00pm</p>
      <p>
        Please note that Nursery to Year 2 classes attend only on Saturdays. All
        children and parents should gather in the main hall at least 10 minutes
        prior to their start time in order for the head teacher to pass on
        important information and to ensure that teachers can take the children
        to their classrooms. Children are not allowed in classrooms without
        adult supervision. It is imperative that parents bring their children
        into school early in order to start their lessons on time. Lateness is
        disruptive for pupils and their teachers.
      </p>

      <h2>Absence</h2>
      <p>
        Pupils may not be absent from School or miss lessons unless there is a
        good reason justifying such absence.
      </p>
      <p>
        A letter requesting permission for these absences must be brought to the
        School in advance or in the event of absence due to medical reasons a
        letter is to be brought by the pupil the following day upon which the
        School operates and handed to the form teacher.
      </p>

      <h2>Behaviour</h2>
      <p>
        As you are aware the School hires the facilities from &quot;East Barnet
        School&quot;. Special care and attention must be taken by pupils to
        conduct themselves in such a manner so as not to give rise to any
        complaints from the staff of &lsquo;East Barnet School'.
      </p>
      <ul>
        <li>
          Pupils are forbidden to touch, deface or otherwise interfere with
          pictures, books, maps or equipment belonging to 'East Barnet School'
          whether inside or outside the classrooms.
        </li>
        <li>
          Pupils are forbidden to bring to school with them any item, which may
          be regarded as Dangerous or likely to cause damage to the classrooms
          or the equipment within the classroom.
        </li>
        <li>
          Children are forbidden to bring radios, 'walkman' or mobile phones to
          school. (Such items may, at the discretion of the teacher or the
          Headmaster, be confiscated during school hours)
        </li>
        <li>
          It is our school's policy for all students to remain in their classes
          during lessons at all times. Permission to go to the toilets should be
          given only in exceptional circumstances and under the supervision of
          an adult. If there is a medical need it is the parents' duty to advise
          the head of the school of the child's condition. Teachers will
          instruct children to use the toilets only during the break and/or
          before and after the lesson.
        </li>
        <li>
          Orderly behaviour, tidy work and good manners are required at all
          times and in particular in the following areas:
        </li>
      </ul>

      <h2>Classroom</h2>
      <ul>
        <li>
          {" "}
          Pupils must wait quietly outside their classrooms until they are
          invited to enter.
        </li>
        <li>
          {" "}
          Pupils must behave themselves in the classroom and carry out the
          instructions of their teacher in silence.
        </li>
        <li>
          {" "}
          Pupils will ensure that the classrooms are left tidy and clean after
          every lesson.
        </li>
        <li> Pupils are strictly forbidden to chew gum in classrooms</li>
        <li>
          {" "}
          Pupils are strictly advised to pay the appropriate respect both to the
          teachers and the adults
        </li>
        <li>
          {" "}
          Pupils should speak only when they are addressed by the teachers or
          when taking part in class discussions. If they wish to speak or
          attract the teacher's attention they should raise their hand.
        </li>
      </ul>

      <h2>Discipline</h2>
      <p>
        The removal of children from the class is prohibited at all times. If a
        teacher experiences a behaviour problem he/she is advised to make a note
        on the 'behavioural record'' form and to inform the Head-teacher
        accordingly. If the situation continues to be a problem the teachers
        should follow the instructions laid down in the 'Chain of Behaviour'
        chart (see below).
      </p>

      <h2>Code of Behaviour</h2>
      <p>
        The school should display to the students the behaviour that it is
        expected to be followed. When enforcing discipline the teachers are
        reminded to refer to the behaviour code on a regular basis.
      </p>
      <p>
        All Children should: - Remember, stop and think before you Act! You are
        responsible for your actions.- Show respect and treat others as you
        would like to be treated (No form of bullying is acceptable).- Walk
        sensibly around the school.Take care of our school, hall and the
        playground. (Put rubbish in its proper place).- Only use appropriate
        language (swearing is not acceptable).- Show respect for other people's
        work and belongings (ask before borrowing).
      </p>

      <h2>School Break</h2>
      <p>The school break will take place between 11:00 and 11:20am.</p>
      <p>
        Pupils must not go into any area, which is out of bounds during break.
      </p>
      <p>
        Fighting amongst pupils, the bullying of pupils by other pupils, and the
        use of bad language will not be tolerated at school. Parents of children
        involved in such conduct will be informed by the Headmaster who may at
        his discretion suspend or exclude such pupils.
      </p>
      <p>
        Pupils will move in a quiet and orderly fashion in the corridors or on
        the stairs. Running will not be allowed within the school building.
      </p>
      <p>
        Pupils who do not pay attention during their lessons, disturb fellow
        pupils, regularly shirk from doing their work, cause damage, answer
        back, generally show that they do not respect their teachers and do not
        obey the rules of the school will be reprimanded. The penalties aim to
        self - correct the pupil and to maintain discipline and the normal
        working of the school.
      </p>
      <p>
        It is strictly prohibited for pupils to leave the school during school
        hours.
      </p>
      <p>
        These penalties may include: <br />
        Contacting and informing parents, payment for whatever damages may have
        been caused, suspension from school and permanent exclusion from school.
      </p>
      <p>
        A decision to suspend or exclude can be taken after a joint discussion
        by the school committee and the head-teacher; and only after the parents
        have been given the opportunity to comment on the matter.
      </p>

      <h2>Smoking</h2>
      <p>
        Smoking amongst pupils is &quot;absolutely&quot; forbidden nor are
        teachers or parents allowed to smoke inside the school building or on
        the school grounds.
      </p>

      <h2>Homework</h2>
      <p>
        Pupils will be given homework each day in accordance with their age.
        Homework is obligatory and not voluntary.
      </p>
      <p>
        In the event that a pupil is absent from school he or she is expected to
        find out from his/her classmates what homework has been set.
      </p>
      <p>
        Where pupils have failed to produce any homework the teacher will notify
        the Headmaster who in turn will advise the parents in writing.
      </p>
      <p>
        Homework diaries will be given to each pupil, which will be the property
        of the pupil. Into these will be written any homework that has been set.
        Parents are expected to sign these homework diaries each day as an
        indication to the teacher that the homework has been done.
      </p>

      <h2>Language Laboratory</h2>
      <p>
        The school aspires to establish a language laboratory in which the
        children will have regular opportunities to learn the Greek language
        (alphabet, spelling, and grammar) with the use of a computer. There are
        various educational programs available that might be useful throughout
        the school.
      </p>

      <h2>School Events</h2>
      <p>Every pupil is expected to attend each school event.</p>

      <h2>Progress</h2>
      <p>
        Open days will be held each term and advance notification will be given
        to parents. Parents are expected to attend these Open Days at which they
        will be informed by the appropriate teacher of the progress their child
        has made during the term.
      </p>
      <p>
        Progress reports will be given at the end of each term and a report card
        will be prepared for each pupil at the end of the Summer Term.
      </p>
      <p>
        The Headmaster will be available during the school hours to discuss any
        matter of concern with parents.
      </p>

      <h2>Dance Lessons</h2>
      <p>
        Both music and dance are part of the school curriculum. The teachers are
        responsible for their pupils' supervision during the dance sessions.
        They should also ensure that the children proceed from and return to
        their classes in an orderly manner without disturbing the other lessons.
      </p>
      <p>
        The teachers should be present at the dance area and are responsible for
        their class behaviour during the dance lessons.
      </p>
    </div>
  );
};

export default Policies;
