import React from "react";
import "./TermDates.css";

const TermDates = () => {
  return (
    <div className="termdates">
      <div>
        <div>
          <h1
            style={{ color: "blue", textAlign: "center", fontWeight: "bolder" }}
          >
            Hellenic School Of High Barnet
            <br />
            School Terms & Holidays 2019/2020
          </h1>
        </div>
        <br />
        <div>
          <div>
            <h2>Autumn Term 2019</h2>
          </div>
          <br />
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Starts</th>
                <th>Ends</th>
                <th>Days</th>
              </tr>
            </thead>
            <tbody>
              <tr className="emptyheader">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>First Half</td>
                <td>
                  Sat 7<sup>th</sup> September 2019
                </td>
                <td>
                  Sat 19<sup>th</sup> October 2019
                </td>
                <td>13</td>
              </tr>
              <tr style={{ color: "red" }}>
                <td>Half Term</td>
                <td>
                  Mon 21<sup>st</sup> October 2019
                </td>
                <td>
                  Sat 26<sup>th</sup> October 2019
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Second Half</td>
                <td>
                  Wed 30<sup>th</sup> October 2019
                </td>
                <td>
                  Sat 21<sup>st</sup> December 2019
                </td>
                <td>16</td>
              </tr>
            </tbody>
          </table>
          <div className="tablenotes">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x227B; Διακοπές
            Χριστουγέννων Δευτέρα 23 Δεκ 2019 – 6 Ιανουαρίου 2020
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x227B; (Christmas
            Holidays Sun 22<sup>nd</sup> Dec. 2019 to Mon 6<sup>th</sup> Jan
            2020)
          </div>
        </div>
        <br />
        <div>
          <div>
            <h2>Spring Term 2020</h2>
          </div>
          <br />
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Starts</th>
                <th>Ends</th>
                <th>Days</th>
              </tr>
            </thead>
            <tbody>
              <tr className="emptyheader">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>First Half</td>
                <td>
                  Wed 8<sup>th</sup> January 2020
                </td>
                <td>
                  Sat 15<sup>th</sup> February 2020
                </td>
                <td>12</td>
              </tr>
              <tr style={{ color: "red" }}>
                <td>Half Term</td>
                <td>
                  Mon 17<sup>th</sup> February 2020
                </td>
                <td>
                  Sat 22<sup>nd</sup> February 2020
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Second Half</td>
                <td>
                  Wed 26<sup>th</sup> February 2020
                </td>
                <td>
                  Sat 4<sup>th</sup> April 2020
                </td>
                <td>12</td>
              </tr>
            </tbody>
          </table>
          <div className="tablenotes">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#x227B; Διακοπές Πάσχα: 6
            to 19 Απριλίου 2020. Barnet Council School Hols: Fri 6 Apr. to Sun
            19 Apr.
            <br />
            <p style={{ color: "blue" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Greek Easter -Πάσχα 19
              Apr. 2020
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; English Easter
              12<sup>th</sup> April 2020).
            </p>
          </div>
        </div>
        <br />
        <div>
          <div>
            <h2>Summer Term 2020</h2>
            <br />
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Starts</th>
                <th>Ends</th>
                <th>Days</th>
              </tr>
            </thead>
            <tbody>
              <tr className="emptyheader">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>First Half</td>
                <td>
                  Wed 22<sup>nd</sup> April 2020
                </td>
                <td>
                  Sat 23<sup>rd</sup> May 2020
                </td>
                <td>9</td>
              </tr>
              <tr style={{ color: "red" }}>
                <td>Half Term</td>
                <td>
                  Mon 25<sup>th</sup> May 2020
                </td>
                <td>
                  Sat 30<sup>th</sup> May 2020
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Second Half</td>
                <td>
                  Wed 3<sup>rd</sup> June 2020
                </td>
                <td>
                  Sat 11<sup>th</sup> July 2020
                </td>
                <td>12</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ fontSize: "27px" }}>
            <div
              className="tablenotes"
              style={{ fontSize: "27px", float: "left" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We operate 36 Wednesdays
              and 37 Saturdays{" "}
            </div>
            <div style={{ float: "right" }}>
              <h1>
                Total <sub>days</sub> 73
              </h1>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermDates;
