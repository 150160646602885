import React from "react";

const TeachingVacancies = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Teaching Vacancies
          </li>
        </ol>
      </nav>

      <p>
        We believe that Hellenic School of High Barnet is a really good place to
        work, whatever the role.
        <br />
        Staff here enjoy good relationships with each other and with students
        too.
      </p>

      <p>
        Students and staff have worked very hard to make HSHB the outstanding
        school it is and we are all proud of our achievements.
      </p>

      <div className="alert alert-success" role="alert">
        <h4 className="alert-heading">New vacancies as of July 2023</h4>
        <p>
          We are currently keen to appoint well qualified and talented
          professionals to the following positions:
        </p>
        <ul>
          <li>
            <strong>Primary Modern Greek school teachers.</strong>
          </li>
        </ul>
        <hr />
        <p className="mb-0">
          If you are an experienced Modern Greek primary school Teacher and wish
          to work at Hellenic School of High Barnet, please:
          <br />
          <ul>
            <li>
              <a href="/contact-details">
                contact the Lower School Headmaster: Mr Vasilis Kalaitzidis
              </a>
              , or
            </li>
            <li>
              send us your CV by e-mail:{" "}
              <a href="mailto:head@hshb.org.uk">head@hshb.org.uk</a>
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default TeachingVacancies;
