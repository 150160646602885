import React from "react";
import kostas_ladas from "../static/assets/photos/staff/kostas_ladas.jpg";

const OurStaff = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Our Staff
          </li>
        </ol>
      </nav>

      <div className="row mb-3">
        <div className="col-md-6">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <th scope="col">
                <strong>Headmasters</strong>
              </th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="card-deck">
                    <div className="card">
                      <div className="card-header">
                        Lower School Headmaster (Nursery to Year 5)
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Vasilis Kalaitzidis</h4>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="card-deck">
                    <div className="card">
                      <div className="card-header">
                        Upper School Headmaster (Year 6 to A Level)
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Jenny Petridou</h4>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered table-hover">
            <thead className="thead-dark">
              <th colspan="2" scope="col">
                <strong>
                  <strong>Parent Committe Leadership Team</strong>
                </strong>
              </th>
            </thead>
            <tbody>
              <tr className="table-success">
                <th scope="row">
                  <strong>Chairman</strong>
                </th>
                <td>
                  <strong>George Keliris</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered table-hover">
            <thead className="thead-dark">
              <th scope="col">
                <strong>Committee Members</strong>
              </th>
            </thead>
            <tbody>
              <tr>
                <td>Andy Antoniou</td>
              </tr>
              <tr>
                <td>Anthea Antoniou</td>
              </tr>
              <tr>
                <td>Nancy Constantine</td>
              </tr>
              <tr>
                <td>Costas Constantinou</td>
              </tr>
              <tr>
                <td>Andrew Kadros</td>
              </tr>
              <tr>
                <td>Maria Pantelli</td>
              </tr>
              <tr>
                <td>Stella Petrou</td>
              </tr>
              <tr>
                <td>Maria Taylor</td>
              </tr>
              <tr>
                <td>Mary Tryphona</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <div id="accordion" role="tablist">
            <div className="card">
              <div className="card-header" role="tab" id="headingOne">
                <h5 className="mb-0">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    The committee
                  </a>
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                role="tabpanel"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    {" "}
                    The committee is vital to the smooth running of the School
                    as they act as Parent Governers keeping the administration
                    and finances of the school in check.
                  </p>
                  <p>
                    They hold termly meetings and being on the committee gives
                    all parents involved, an insight and more importantly a
                    voice in making the policies that mold the next academic
                    year, from spending plans, to class sizes and beyond. They
                    hold an annual general meeting in September when new
                    committee members are elected and all parents are invited to
                    be present.
                  </p>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" role="tab" id="headingTwo">
                <h5 className="mb-0">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    href="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Why join the committee?
                  </a>
                </h5>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    We actively encourage parents to become involved. As a
                    registered <strong>non for profit charity</strong> the
                    School is managed by the committee of parents, and act to
                    better the school and its facilities for all. The word
                    ‘Committee’ can sound off putting, but the reality is that
                    without the help of this small group of parents and the
                    skills and time they volunteer, the school would not
                    function.
                  </p>
                  <p>
                    Any time that you as a parent can offer your services of
                    skills, helps out immensly, and not only is being part of
                    the committee a truly valuable role, it is also hugely
                    rewarding and great fun. It gives you a real insight into
                    what goes on behind the scenes at the School as well as the
                    opportunity to have a genuine impact on your child’s
                    education.
                  </p>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" role="tab" id="headingThree">
                <h5 className="mb-0">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    href="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How it works
                  </a>
                </h5>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    You can join at any time, and there is no time limit to how
                    long you can serve. You wil find the members very open to
                    listening to new ideas and discussing what can be improved
                    and how to do so. The best time to join is always at the
                    begining of the school year, and with the Annual General
                    Meeting (AGM) taking place in September, it's an ideal
                    jumping on point, regardless of if you just want to help out
                    with the tuck shop, or be involved in the finances, everyone
                    is welcome and no help is turned away.
                  </p>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" role="tab" id="headingFour">
                <h5 className="mb-0">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    href="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Fundraisers
                  </a>
                </h5>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Our fundraisers do an amazing job co-ordinating fundraising
                    and social events each term. This is a fun and rewarding
                    role that directly benefits your children as all funds
                    raised are spent on resources for the School. Publicity,
                    gift gathering and events co-ordination are some of the
                    things you will have chance to be involved in.
                  </p>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" role="tab" id="headingFive">
                <h5 className="mb-0">
                  <a
                    className="collapsed"
                    data-toggle="collapse"
                    href="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Volunteers and non elected members
                  </a>
                </h5>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFive"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Our fundraisers do an amazing job co-ordinating fundraising
                    and social events each term. This is a fun and rewarding
                    role that directly benefits your children as all funds
                    raised are spent on resources for the School. Publicity,
                    gift gathering and events co-ordination are some of the
                    things you will have chance to be involved in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStaff;
