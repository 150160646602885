import React from "react";

const Fees = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Fees
          </li>
        </ol>
      </nav>

      <div
        className="alert alert-warning alert-dismissible fade show"
        role="alert"
      >
        <h4 className="alert-heading">Sponsorship</h4>
        <p className="lead">
          The school relies quite heavily upon the generous donations by
          individuals and companies, and is looking for sponsorship for
          particular events, such as the Annual Dinner & Dance, Sports Day etc.
          If you or your company can help either providing financial
          contributions, supplies or advertsing, please contact the Chairman or
          the Headmaster.
        </p>

        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <h2>Fees 2023 - 24</h2>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Year Group</th>
            <th scope="col">Yearly Payment</th>
            <th scope="col">8 Standing Order Payments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nursery</td>
            <td>£375.00</td>
            <td>£46.88</td>
          </tr>
          <tr>
            <td>Reception</td>
            <td>£375.00</td>
            <td>£46.88</td>
          </tr>
          <tr>
            <td>Years 1-6</td>
            <td>£375.00</td>
            <td>£46.88</td>
          </tr>
          <tr>
            <td>GCSE YEAR 1&2</td>
            <td>£425.00</td>
            <td>£53.13</td>
          </tr>
          <tr>
            <td>A Level</td>
            <td>£525.00</td>
            <td>£65.63</td>
          </tr>
        </tbody>
      </table>

      <h1>METHOD OF PAYMENT</h1>
      <p>
        Pay for the academic year in full along with your registration by bank
        transfer
        <br />
        or
        <br />
        Pay in 8 equal Standing Order instalments set up on registration, with
        first payment taken from your account in September 2023.
      </p>
    </div>
  );
};

export default Fees;

/*<h2>Fees {% now "Y" %}</h2>

<table className="table table-hover">
    <thead>
    <tr>

        <th scope="col">Year Group</th>
        <th scope="col">Yearly Payment</th>
        <th scope="col">8 Standing Order Payments</th>
    </tr>
    </thead>
    <tbody>
    {% for fee in fees %}
    <tr>

        <td>{{fee.year_group}}</td>
        <td>£{{fee.yearly_payment}}</td>
        <td>£{{fee.yearly_payment|div:"8.0"}}</td>
    </tr>
    {% endfor%}
    </tbody>
</table>*/
