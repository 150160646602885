import React from "react";

const Aesa = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            AESA
          </li>
        </ol>
      </nav>

      <h2>THE INDEPENDENT GREEK SCHOOLS OF ENGLAND (AESA)</h2>

      <p>
        The Independent Greek Schools of England (AESA) were set up in 1980, so
        relative to the other part time Greek Schools in the UK they are fairly
        new. They are however dynamic and progressive and boast productive
        activity and great achievements, due to the independant nature of each
        school, sonething that we at the Hellenic School of High Barnet, are
        proud to be founding memebers of.
      </p>
      <p>
        The schools that comprise AESA strive not only to teach our children the
        Greek language, but also to educate them in the Greek Orthodox religion
        and the history and culture of the Hellenes. They strive to cultivate in
        them the knowledge and the realisation that they are Greek!
      </p>
      <p>
        The AESA schools function at different times with some like ourselves
        operating twice a week and taking children from the age of 4 to 18 years
        old. In the nursery and the younger classes the emphasis is on teaching
        and improving the oral language and to encourage friendly relationships
        between the children, whereas in the older classes the children are
        prepared for GCSE and A level examinations.
      </p>
      <p>
        Each AESA school is self sufficient and totally independent. This is
        what makes them different from other schools. Each school is governed by
        a Committee of parents of the children who study at the school. The
        Committee are democratically elected by the parents each year. All the
        parents are encouraged to take an active part in the day to day running
        of their school and many of them work tirelessly for the success of
        their school. this precludes political motivations that all to often
        lead to other schools who are not independant following a curriculem
        that is set down in a rigid fashion, with little or no regard for the
        fact that as Greek speaking in the UK, we have different strengths and
        weaknesses, that need to be taught towards.
      </p>
      <p>
        Despite this personal independence the AESA schools recognise that there
        are circumstances which demand collective action, power through unity of
        effort, solidarity and support between the schools. The AESA
        organisation was set up to cater for these needs and to represent the
        AESA schools at EFEPE (meeting of all the Greek Language schools in the
        UK.
      </p>
      <p>
        AESA became a member of EFEPE in 1992 and whilst Hellenism is going
        through difficult times, it needs the support of all Greek people. We,
        the Greeks who live away from the land of our ancestors, have an
        obligation to maintain our culture, our religion and our traditions. We
        have a duty and the power to keep Hellenism alive and thriving. This
        will always be the main target of AESA.
      </p>
    </div>
  );
};

export default Aesa;
