import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <span className="text-muted">© The Hellenic School of High Barnet</span>
      </div>
    </footer>
  );
};

export default Footer;
