import React from "react";

const ClassesCirriculum = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Classes & Curriculum
          </li>
        </ol>
      </nav>

      <div
        className="alert alert-warning alert-dismissible fade show"
        role="alert"
      >
        <h4 className="alert-heading">Warning!</h4>
        <p className="lead">
          Please note that all <strong>Greek Schools</strong> operate{" "}
          <strong>1 year behind</strong> the UK equivalent years for classes
          from Nursery to Year 6.
        </p>

        <hr />
        <p>
          i.e. If in Year 2 in English School, you will be in Year 1 in Greek
          School.
        </p>

        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="card-deck mb-3">
        <div className="card">
          <div className="card-header">Nursery</div>
          <div className="card-body">
            <h4 className="card-title">Ages 3 to 5 years.</h4>
            <p className="lead">Saturday morning only 9:30 to 13:00</p>
            <p className="card-text">
              This class incorporates activities to improve children's fine
              motor skills, including Greek songs, poems, stories and
              conversation.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Reception</div>
          <div className="card-body">
            <h4 className="card-title">Ages 5 to 6 years.</h4>
            <p className="lead">Saturday morning only 9:30 to 13:00</p>
            <p className="card-text">
              <ol>
                <li>Myself (Ο εαυτόs μου)</li>
                <li>Family (Οικογένεια μου)</li>
                <li>Clothing (Τα ρούχα μου)</li>
                <li>Home (Το σπίτι μου)</li>
                <li>
                  Surrounding world, including topics on animals, nature,
                  colours (Ο κόσμοs γύρω μου)
                </li>
                <li>Seasons (Ο καιρόs)</li>
              </ol>
            </p>
          </div>
        </div>
      </div>

      <div className="card-deck mb-3">
        <div className="card">
          <div className="card-header">Years 1 to 6</div>
          <div className="card-body">
            <h4 className="card-title">Ages 6 to 12 years.</h4>
            <p className="card-text lead">
              Saturday morning only 9:30 to 13:00 <br />
              <strong>
                & Wednesday evening 18:10 to 19:40 (Years 3- 6 only)
              </strong>
            </p>
            <p className="card-text">
              We follow the syllabus recommended by the Greek and Cypriot
              embassies including
            </p>
          </div>
        </div>

        <div className="card">
          <div className="card-header">GCSE</div>
          <div className="card-body">
            <h4 className="card-title">Ages 13 to 14 years.</h4>
            <p className="card-text lead">
              Saturday morning only 9:30 to 13:00 <br />
              <strong>& Wednesday evening 18:10 to 19:40</strong>
            </p>
            <p className="card-text">
              The Edexcel GCSE syllabus is followed. At the end of the course
              the children are expected to take their GCSE exams.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassesCirriculum;
