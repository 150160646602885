import React from "react";

const SocialEvents = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Social Events
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default SocialEvents;
